import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, Card, Switch, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, MessageOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { TextArea } = Input;

const FeedbackPage = () => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        setIsSubmitting(true);
        try {
            const response = await fetch('https://email.delayel06.workers.dev/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: values.likedSite, // Send yes or no in the name value
                    feedback: values.feedback, // Send feedback message in the feedback value
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText || `HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.success) {
                message.success('Feedback sent successfully!');
                form.resetFields();
            } else {
                message.error(data.message || 'Failed to send feedback. Please try again.');
            }
        } catch (error) {
            console.error('Feedback submission error:', error);
            message.error(error.message || 'An error occurred. Please try again.');
        }
        setIsSubmitting(false);
    };

    const pageStyles = {
        minHeight: '100vh',
        width: '100%',
        background: isDarkMode 
            ? 'linear-gradient(to right, #1a1a1a, #2d2d2d)'
            : 'linear-gradient(to right, #c4dcf5, #a1c4fd)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        transition: 'background 0.3s ease',
    };

    const formContainerStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: '20px',
    };

    const cardStyles = {
        width: '100%',
        maxWidth: '500px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '15px',
        background: isDarkMode ? '#1f1f1f' : 'white',
        color: isDarkMode ? 'white' : 'black',
        button: {
            backgroundColor: isDarkMode ? 'orange' : undefined,
            borderColor: isDarkMode ? 'orange' : undefined,
        },
    };

    return (
        <div style={pageStyles}>
            <Switch
                checked={isDarkMode}
                onChange={setIsDarkMode}
                checkedChildren="🌙"
                unCheckedChildren="☀️"
                style={{ position: 'absolute', top: '1rem', right: '1rem', transform: 'scale(1.5)', marginRight: '1rem' }}
            />
            <Button
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
                style={{ position: 'absolute', top: '1rem', left: '1rem', transform: 'scale(1.5)', marginLeft: '1rem' }}
            />
            <div style={formContainerStyles}>
                <Card style={cardStyles}>
                    <Title level={2} style={{ textAlign: 'center', marginBottom: '20px', color: isDarkMode ? 'white' : 'black' }}>
                        Have you found the website useful?
                    </Title>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="likedSite"
                            rules={[{ required: true, message: 'Please select an option' }]}
                        >
                            <Radio.Group>
                                <Radio value="yes" style={{ color: isDarkMode ? 'white' : 'black' }}>Yes !</Radio>
                                <Radio value="no" style={{ color: isDarkMode ? 'white' : 'black' }}>No :(</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="feedback"
                            rules={[{ required: true, message: 'Please enter your feedback' }]}
                        >
                            <TextArea
                                prefix={<MessageOutlined />}
                                rows={4}
                                placeholder="Your Feedback"
                                size="large"
                                style={{ backgroundColor: 'white', color: 'white'}}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                block
                                loading={isSubmitting}
                                style={cardStyles.button}
                            >
                                Send Feedback
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default FeedbackPage;
