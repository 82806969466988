import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Card, Typography, Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowRightOutlined, ChromeOutlined, MessageOutlined } from '@ant-design/icons';
import './styles.css';


const { Text, Title } = Typography;

const SearchPage = () => {
  const [companies, setCompanies] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [sitesAnalyzed, setSitesAnalyzed] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const logoSrc = isDarkMode ? 'logo2white.png' : 'logo2.png';

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const baseUrl = 'https://patient-bush-a521.delayel06.workers.dev';
        const headers = {
          'apikey': process.env.REACT_APP_API_KEY,
          'Accept': 'application/json',
        };

        const response = await fetch(`${baseUrl}/websites`, { headers });
        const data = await response.json();
        
        const formattedCompanies = data
          .filter(company => typeof company.website_name === 'string')
          .map(company => ({
            id: company.website_name,
            name: company.pretty_name,
            rawName: company.website_name,
            website: `https://${company.url}`,
            favicon: `https://www.google.com/s2/favicons?domain=${company.url}&sz=32`
          }));
        
        setCompanies(formattedCompanies);
        setSitesAnalyzed(data.length);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = companies.filter(company => {
        const searchLower = searchTerm.toLowerCase();
        const companyName = (company.name || '').toLowerCase();
        const companyWebsite = (company.website || '').toLowerCase();
        
        return companyName.includes(searchLower) || companyWebsite.includes(searchLower);
      });
      
      setFilteredCompanies(results.slice(0, 3));
    } else {
      setFilteredCompanies([]);
    }
  }, [searchTerm, companies]);

  const handleCompanyClick = (company) => {
    navigate(`/page/${company.name}`, { state: { company } });
  };

  const containerStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  };

  const inputWidth = inputFocused ? '70%' : '30%';
  const inputStyles = {
    width: inputWidth,
    padding: '0.5rem 1rem',
    fontSize: '1.5rem',
    borderRadius: '9999px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
    height: '4.5rem',
    transition: 'all 0.3s ease-in-out',
  };

  const buttonStyles = {
    marginTop: '1rem', 
    height: '3.5rem', 
    width: '15%',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
    transition: 'all 0.3s ease-in-out',
    fontFamily: 'Roboto, sans-serif',
  };

  const cardContainerStyles = {
    position: 'absolute',
    top: '4rem',
    width: inputWidth,
    zIndex: 1,
    backgroundColor: 'white',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
    borderRadius: '0 0 10px 10px',
  };

  const featuresData = [
    { icon: "📝", title: "Clear Summary", description: "A clear summary of the essential points, without legal jargon." },
    { icon: "🎯", title: "Trust Score", description: "A trust score to assess the risks associated with a site." },
    { icon: "🌐", title: "Privacy Alternatives", description: "Alternatives that respect your privacy, if necessary." },
    { icon: "📊", title: "Simplified Analysis", description: "PoliSee is more than a tool: it's a way of regaining control over your personal data." }
  ];

  const styles = {
    mainSlogan: {
      color: 'white',
      textAlign: 'center',
      marginBottom: '1rem',
      fontSize: '2.5rem',
      fontWeight: 'bold',
      fontFamily: 'Poppins, sans-serif', // Applied Poppins font
    },
    featureCard: {
      width: '240px', // Reduced from 280px
      margin: '0.5rem',
      borderRadius: '15px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      background: isDarkMode ? '#1f1f1f' : 'white',
      color: isDarkMode ? 'white' : 'black',
      transition: 'transform 0.3s ease',
    },
    featuresContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      maxWidth: '1200px',
      margin: '2rem auto',
      padding: '0 1rem',
      gap: '1rem', // Added gap for consistent spacing
    },
    darkModeSwitch: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      transform: 'scale(1.5)', // Made switch bigger
      marginRight: '1rem',
    },
    mainContainer: {
      minHeight: '100vh',
      width: '100%',
      background: isDarkMode 
        ? 'linear-gradient(to right, #1a1a1a, #2d2d2d)'
        : 'linear-gradient(to right, #c4dcf5, #a1c4fd)',
      transition: 'background 0.3s ease',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    inputStyles: {
      ...inputStyles,
      backgroundColor: 'white',
      color: isDarkMode ? 'white' : 'black',
      borderColor: isDarkMode ? '#555' : '#d9d9d9',
    },
    searchResults: {
      ...cardContainerStyles,
      backgroundColor: isDarkMode ? '#333' : 'white',
      color: isDarkMode ? 'orange' : 'black',
    },
    buttonPrimary: {
      ...buttonStyles,
      backgroundColor: isDarkMode ? 'orange' : '#1890ff',
      borderColor: isDarkMode ? 'orange' : '#1890ff',
      color: 'white',
    },
    buttonDefault: {
      ...buttonStyles,
      backgroundColor: isDarkMode ? '#141414' : 'white',
      borderColor: isDarkMode ? '#434343' : '#d9d9d9',
      color: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.85)',
    },
    statsText: {
      fontSize: '1.2rem',
      color: isDarkMode ? 'white' : '#333',
      marginBottom: '1rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    logo: {
      width: '25%',
      paddingTop: '3rem',
      display: 'block',
      margin: '0 auto',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      gap: '1rem',
      marginTop: '1rem',
      flexWrap: 'wrap', // Allow buttons to wrap on small screens
      padding: '0 1rem',
    },
    responsiveButton: {
      height: 'auto',
      minHeight: '3.5rem',
      width: 'auto',
      minWidth: '200px',
      maxWidth: '300px',
      padding: '0.5rem 1.5rem',
      fontSize: '1.1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      flexGrow: 1,
      '@media (max-width: 768px)': {
        width: '100%',
      },
    },
  };

  // Merge responsiveButton with existing button styles
  const responsiveButtonPrimary = {
    ...styles.buttonPrimary,
    ...styles.responsiveButton,
  };

  const responsiveButtonDefault = {
    ...styles.buttonDefault,
    ...styles.responsiveButton,
  };

  const cardContent = (company) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <img 
        src={company.favicon} 
        alt="" 
        style={{ width: '16px', height: '16px' }}
        onError={(e) => e.target.style.display = 'none'}
      />
      <span>{company.name}</span>
      <span style={{ 
        marginLeft: 'auto', 
        color: isDarkMode ? '#888' : '#666',
        fontSize: '0.9em' 
      }}>
        {company.website}
      </span>
    </div>
  );

  return (
    <div style={styles.mainContainer}>
      <Switch
        checked={isDarkMode}
        onChange={setIsDarkMode}
        checkedChildren="🌙"
        unCheckedChildren="☀️"
        style={styles.darkModeSwitch}
      />
      
      <img 
        src={logoSrc} 
        alt="Company Logo" 
        style={styles.logo}
      />
      
      <div style={styles.mainSlogan}>
        Understand privacy policies at a glance!
      </div>
      
      <Text 
        style={styles.statsText}
      >
        More than {sitesAnalyzed} sites analyzed!
      </Text>
      
      <div style={containerStyles} ref={containerRef}>
        <Input 
          placeholder="Company Name" 
          size="large"
          style={styles.inputStyles}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={isDarkMode ? 'dark-mode-input' : ''}
        />
        <div style={styles.searchResults}>
          {filteredCompanies.map(company => (
            <Card 
              key={company.id}
              style={{
                cursor: 'pointer',
                marginBottom: 0,
                transition: 'background-color 0.3s ease-in-out',
                backgroundColor: isDarkMode ? '#333' : 'white',
                color: isDarkMode ? 'white' : 'black',
              }}
              onClick={() => handleCompanyClick(company)}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = isDarkMode ? '#404040' : '#f0f0f0'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = isDarkMode ? '#333' : 'white'}
            >
              {cardContent(company)}
            </Card>
          ))}
        </div>
        <div style={styles.buttonContainer}>
          <Button 
            type="primary" 
            shape="round" 
            size="large" 
            style={responsiveButtonPrimary}
            onClick={() => navigate('/companies')}
            icon={<ArrowRightOutlined />}
          >
            Explore Policies
          </Button>
          <Button 
            type="default" 
            shape="round" 
            size="large" 
            style={responsiveButtonDefault}
            onClick={() => navigate('/chrome-extension')}
            icon={<ChromeOutlined />}
          >
            Chrome Extension
          </Button>
          <Button 
            type="default" 
            shape="round" 
            size="large" 
            style={responsiveButtonDefault}
            onClick={() => navigate('/feedback')}
            icon={<MessageOutlined />}
          >
            Feedback
          </Button>
        </div>
      </div>

      <div style={styles.featuresContainer}>
        {featuresData.map((feature, index) => (
          <Card
            key={index}
            style={styles.featureCard}
            hoverable
            className={isDarkMode ? 'dark-mode' : ''}
          >
            <div style={{ fontSize: '2rem', textAlign: 'center', marginBottom: '1rem' }}>
              {feature.icon}
            </div>
            <Title level={4} style={{ textAlign: 'center', color: isDarkMode ? 'white' : 'black' }}>
              {feature.title}
            </Title>
            <Text style={{ display: 'block', textAlign: 'center', color: isDarkMode ? '#ddd' : '#666' }}>
              {feature.description}
            </Text>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default SearchPage;