import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card, message, Button, Slider, Select, Layout, Spin, Progress, Switch } from 'antd'; // Added Switch
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, MenuOutlined } from '@ant-design/icons'; // Added MenuOutlined

const { Meta } = Card;
const { Option } = Select;
const { Sider, Content } = Layout;

// Constants
const SCORE_RANGES = {
  EXCELLENT: 80,
  GOOD: 60,
  MODERATE: 40,
  POOR: 20
};

const DEFAULT_IMAGE = 'https://global-sensors.com/wp-content/uploads/2021/07/test.jpg';

// Add this helper function after the existing constants
const getFaviconUrl = (url) => {
  try {
    const domain = url
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=128`;
  } catch (e) {
    return DEFAULT_IMAGE;
  }
};

// Styles object moved outside component
const getStyles = (isDarkMode, isMobile) => ({
  mainContainer: {
    minHeight: '100vh',
    background: isDarkMode 
      ? 'linear-gradient(to right, #1a1a1a, #2d2d2d)'
      : 'linear-gradient(to right, #c4dcf5, #a1c4fd)',
    transition: 'all 0.3s ease',
    position: 'relative',
  },
  sider: {
    background: isDarkMode ? '#141414' : '#fff',
    padding: isMobile ? '1rem' : '2rem 1rem',
    position: isMobile ? 'fixed' : 'fixed',
    height: isMobile ? 'auto' : '100vh',
    left: 0,
    top: 0,
    zIndex: 999,
    width: '250px',
    transform: isMobile ? 'translateX(-100%)' : 'translateX(0)',
    transition: 'transform 0.3s ease',
    boxShadow: '2px 0 8px rgba(0,0,0,0.15)',
  },
  siderVisible: {
    transform: 'translateX(0)',
  },
  content: {
    marginLeft: isMobile ? '0' : '250px',
    padding: isMobile ? '1rem' : '2rem',
    minHeight: '100vh',
    transition: 'margin-left 0.3s ease',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: isMobile 
      ? '1fr' 
      : 'repeat(auto-fill, minmax(280px, 1fr))',
    gap: isMobile ? '1rem' : '2rem',
    padding: isMobile ? '1rem' : '0',
  },
  scoreContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    borderRadius: '20px',
    padding: '6px 14px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  },
  switchContainer: {
    position: 'fixed',
    top: isMobile ? '1rem' : '2rem',
    right: isMobile ? '1rem' : '2rem',
    zIndex: 1000,
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '2rem',
    '@media (max-width: 768px)': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  mobileMenuButton: {
    position: 'fixed',
    top: '1rem',
    left: '1rem',
    zIndex: 1000,
    display: isMobile ? 'block' : 'none',
  },
});

// Utility functions
const getScoreColor = (score) => {
  const numScore = parseInt(score);
  if (isNaN(numScore)) return '#666';
  if (numScore >= SCORE_RANGES.EXCELLENT) return '#52c41a';
  if (numScore >= SCORE_RANGES.GOOD) return '#73d13d';
  if (numScore >= SCORE_RANGES.MODERATE) return '#ffa940';
  if (numScore >= SCORE_RANGES.POOR) return '#ff7a45';
  return '#f5222d';
};

// Memoized components
const CompanyCard = ({ company, isDarkMode, onClick }) => {
  const styles = useMemo(() => getStyles(isDarkMode), [isDarkMode]);
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <Card
      hoverable
      className={`company-card ${isDarkMode ? 'dark' : ''}`}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      style={{
        transform: isHovered && !isMobile ? 'scale(1.05)' : 'scale(1)',
        transition: 'transform 0.3s',
        backgroundColor: 'white', // Keep cards white
        color: 'black', // Keep text black
        width: '100%',
      }}
      cover={
        <div style={{
          background: 'white', // Always white background, regardless of dark mode
          position: 'relative'
        }}>
          <img
            alt={company.displayName}
            src={getFaviconUrl(company.url)}
            style={{
              width: '100%',
              height: '140px',
              padding: '1rem',
              objectFit: 'contain'
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = DEFAULT_IMAGE;
            }}
          />
          <div style={{
            ...styles.scoreContainer,
            background: getScoreColor(company.score)
          }}>
            {company.score}
          </div>
        </div>
      }
      onClick={onClick}
    >
      <Meta title={company.displayName} description={company.description} />
    </Card>
  );
};

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Added isLoading state
  const [progress, setProgress] = useState(0); // Added progress state
  const [isDarkMode, setIsDarkMode] = useState(false); // Added isDarkMode state
  const [scoreFilter, setScoreFilter] = useState([0, 100]);
  const [sortOrder, setSortOrder] = useState('default');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [siderVisible, setSiderVisible] = useState(false);
  const navigate = useNavigate();
  const styles = useMemo(() => getStyles(isDarkMode, isMobile), [isDarkMode, isMobile]);

  // Memoized data processing
  const filteredAndSortedCompanies = useMemo(() => {
    return companies
      .filter(company => {
        const score = parseInt(company.score);
        return !isNaN(score) && score >= scoreFilter[0] && score <= scoreFilter[1];
      })
      .sort((a, b) => {
        if (sortOrder === 'asc') return parseInt(a.score) - parseInt(b.score);
        if (sortOrder === 'desc') return parseInt(b.score) - parseInt(a.score);
        return 0;
      });
  }, [companies, scoreFilter, sortOrder]);

  // Memoized callbacks
  const handleCardClick = useCallback((company) => {
    navigate(`/page/${company.name}`, { state: { company } });
  }, [navigate]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const baseUrl = 'https://patient-bush-a521.delayel06.workers.dev';
        const headers = {
          'apikey': process.env.REACT_APP_API_KEY,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        };

        const response = await fetch(`${baseUrl}/websites`, { headers });
        const websites = await response.json();

        const formattedCompanies = await Promise.all(
          websites.map(async (site) => {
            try {
              const scoreResponse = await fetch(`${baseUrl}/web/${site.website_name}`, { headers });
              const scoreData = await scoreResponse.json();
              
              return {
                name: site.website_name,
                displayName: site.pretty_name,
                image: '/fallback-image.png',
                description: site.url,
                score: scoreData.final_score ? `${scoreData.final_score}/100` : 'N/A',
                url: site.url
              };
            } catch (error) {
              console.error(`Error fetching data for ${site.website_name}:`, error);
              return null;
            }
          })
        );

        setCompanies(formattedCompanies.filter(Boolean));
      } catch (err) {
        setError('Unable to load company data. Please try again later.');
        message.error('Failed to load companies');
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : 100));
      }, 100); // Increase progress slowly
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setSiderVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (error) {
    return (
      <div style={styles.mainContainer}>
        <h2 style={{ color: isDarkMode ? 'white' : 'black' }}>
          Error loading companies: {error}
        </h2>
      </div>
    );
  }

  return (
    <Layout style={styles.mainContainer}>
      {isMobile && (
        <Button
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => setSiderVisible(!siderVisible)}
          style={styles.mobileMenuButton}
        />
      )}

      <div style={styles.switchContainer}>
        <Switch
          checked={isDarkMode}
          onChange={setIsDarkMode}
          checkedChildren="🌙"
          unCheckedChildren="☀️"
        />
      </div>

      <Sider 
        width={250} 
        style={{
          ...styles.sider,
          ...(siderVisible && styles.siderVisible)
        }}
      >
        <div style={styles.filterContainer}>
          <Button 
            icon={<ArrowLeftOutlined />} 
            onClick={() => navigate('/')}
            type={isDarkMode ? 'default' : 'primary'}
            block
          >
            Back
          </Button>

          <div>
            <h4 style={{ color: isDarkMode ? 'white' : 'black' }}>Score Range</h4>
            <Slider
              range
              defaultValue={[0, 100]}
              onChange={setScoreFilter}
            />
          </div>

          <div>
            <h4 style={{ color: isDarkMode ? 'white' : 'black' }}>Sort By</h4>
            <Select
              defaultValue="default"
              onChange={setSortOrder}
              style={{ width: '100%' }}
            >
              <Option value="default">Default</Option>
              <Option value="asc">Score (Low to High)</Option>
              <Option value="desc">Score (High to Low)</Option>
            </Select>
          </div>
        </div>
      </Sider>

      <Content 
        style={styles.content}
        onClick={() => isMobile && siderVisible && setSiderVisible(false)}
      >
        {isLoading ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spin size="large" />
            <Progress percent={progress} status="active" style={{ width: '80%', marginTop: '1rem' }} /> {/* Added Progress bar */}
          </div>
        ) : (
          <div style={styles.cardContainer}>
            {filteredAndSortedCompanies.map((company) => (
              <CompanyCard
                key={company.name}
                company={company}
                isDarkMode={isDarkMode}
                onClick={() => handleCardClick(company)}
              />
            ))}
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Companies;