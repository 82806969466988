import React, { useEffect, useState } from "react";
import { Typography, Card, Divider, Button, Switch } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";

const { Title, Text, Paragraph } = Typography;

const getRiskColor = (severity) => ({
  Red: '#ff4d4f',
  Orange: '#fa8c16',
  Yellow: '#faad14',
  Green: '#52c41a'
}[severity]);

const getScoreColor = (score) => {
  if (score >= 80) return '#52c41a'; // Green
  if (score >= 60) return '#8bc34a'; // Light Green
  if (score >= 40) return '#faad14'; // Yellow
  if (score >= 20) return '#fa8c16'; // Orange
  return '#ff4d4f'; // Red
};

const getFaviconUrl = (url) => {
  try {
    return `https://icons.duckduckgo.com/ip3/${url}.ico`;
  } catch (e) {
    return null;
  }
};

const NewPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [websiteDetails, setWebsiteDetails] = useState(null);

  const styles = {
    pageContainer: {
      maxWidth: "800px",
      margin: "2rem auto",
      padding: "2rem 3rem",
      background: "#fafafa",
      borderRadius: "12px",
      boxShadow: "0 8px 24px rgba(0,0,0,0.1)",
      position: "relative",
      overflow: "hidden",
      color: isDarkMode ? "white" : "black",
      transition: 'background 0.3s ease, color 0.3s ease',
      border: "1px solid #e0e0e0",
      '&::before': {
        content: '""',
        position: "absolute",
        top: 0,
        left: "20px",
        height: "100%",
        width: "1px",
        background: "#f0f0f0"
      }
    },
    errorContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    messageText: {
      color: '#ff4d4f',
    },
    websiteName: {
      textAlign: "center",
      fontFamily: "'Roboto Condensed', sans-serif",
      marginBottom: "2rem",
    },
    scoreSection: {
      textAlign: "center",
      margin: "2rem 0",
      padding: "1rem",
      background: "#f8f9fa",
      borderRadius: "8px",
    },
    scoreText: {
      margin: 0,
      transition: 'color 0.3s ease',
    },
    riskSection: {
      marginBottom: "2rem",
      border: "none",
      borderRadius: "8px",
    },
    riskTitle: {
      fontFamily: "'Roboto Condensed', sans-serif",
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      marginBottom: "1rem",
    },
    infoItem: {
      marginBottom: "1rem",
      padding: "0.5rem 0",
    },
    backButton: {
      position: 'absolute',
      top: '1rem',
      left: '1rem',
      zIndex: 100,
      backgroundColor: isDarkMode ? 'orange' : undefined,
      borderColor: isDarkMode ? 'orange' : undefined,
    },
  };

  useEffect(() => {
    const fetchWebsiteData = async () => {
      const apiKey = process.env.REACT_APP_API_KEY; 
      const websiteName = window.location.pathname.split("/").pop(); // Dynamic website name

      try {
        // First fetch the website details to get the pretty name and URL
        const detailsResponse = await fetch(
          `https://patient-bush-a521.delayel06.workers.dev/websites`,
          { headers: { apikey: apiKey } }
        );
        
        if (detailsResponse.ok) {
          const websites = await detailsResponse.json();
          const currentWebsite = websites.find(site => site.website_name === websiteName);
          setWebsiteDetails(currentWebsite);
        }

        // Then fetch the analysis data
        const response = await fetch(
          `https://patient-bush-a521.delayel06.workers.dev/web/${websiteName}`,
          { headers: { apikey: apiKey } }
        );

        if (response.ok) {
          const result = await response.json();
          setData(result);
        } else {
          setError(true);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchWebsiteData();
  }, []);

  if (loading) {
    return <LoadingScreen setLoading={setLoading} />;
  }

  if (error) {
    return (
      <div style={styles.errorContainer}>
        <h2 style={styles.messageText}>Error fetching data. Please try again later.</h2>
      </div>
    );
  }

  const { website_name, information, final_score } = data;

  const groupedInfo = information.reduce((acc, info) => {
    if (!acc[info.severity_tag]) {
      acc[info.severity_tag] = [];
    }
    acc[info.severity_tag].push(info);
    return acc;
  }, {});

  const severityOrder = ["Red", "Orange", "Yellow", "Green"];
  const severityTitles = {
    Red: "High Risk",
    Orange: "Moderate Risk",
    Yellow: "Low Risk",
    Green: "Good Practices",
  };

  const pageContainerStyles = {
    minHeight: '100vh',
    width: '100%',
    background: isDarkMode ? 'black' : 'linear-gradient(to right, #c4dcf5, #a1c4fd)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
  };

  return (
    <div style={pageContainerStyles}>
      <Switch
        checked={isDarkMode}
        onChange={setIsDarkMode}
        checkedChildren="🌙"
        unCheckedChildren="☀️"
        style={{ position: 'absolute', top: '1rem', right: '1rem', transform: 'scale(1.5)' }}
      />
      <Button
        icon={<ArrowLeftOutlined />}
        style={styles.backButton}
        onClick={() => navigate(-1)}
        type="primary"
        size="large"
      >
        Back
      </Button>
      <Card style={styles.pageContainer}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem', marginBottom: '2rem' }}>
          {websiteDetails?.url && (
            <img
              src={getFaviconUrl(websiteDetails.url)}
              alt="Website logo"
              style={{
                width: '48px',
                height: '48px',
                objectFit: 'contain'
              }}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
            />
          )}
          <Title level={1} style={styles.websiteName}>
            {websiteDetails?.pretty_name || website_name}
          </Title>
        </div>

        <Card style={styles.scoreSection}>
          <Title 
            level={2} 
            style={{ 
              ...styles.scoreText,
              color: getScoreColor(final_score)
            }}
          >
            Trust Score: {final_score}/100
          </Title>
        </Card>

        {severityOrder.map((severity) =>
          groupedInfo[severity] ? (
            <Card
              key={severity}
              style={{
                ...styles.riskSection,
                borderLeft: `4px solid ${getRiskColor(severity)}`
              }}
            >
              <Title level={3} style={styles.riskTitle}>
                {severityTitles[severity]}
              </Title>
              
              {groupedInfo[severity].map((info, index) => (
                <div key={index} style={styles.infoItem}>
                  <Text strong style={{ display: 'block', marginBottom: '0.5rem' }}>
                    {info.title}
                  </Text>
                  <Paragraph style={{ margin: 0 }}>
                    {info.details}
                  </Paragraph>
                  {index < groupedInfo[severity].length - 1 && (
                    <Divider style={{ margin: '1rem 0' }} />
                  )}
                </div>
              ))}
            </Card>
          ) : null
        )}
      </Card>
    </div>
  );
};

export default NewPage;
